import React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { BackgroundType } from '../shared/interfaces/theme.interface';
import Stack, {
  onEntryChange,
} from '@shared/contentstack/live-preview/live-preview';
import {
  LivePreviewSelect,
  LivePreviewToggle,
} from '@src/shared/contentstack/live-preview/widgets/live-preview-widgets';
import { LivePreviewHeader } from '@src/shared/contentstack/live-preview/widgets/live-preview-widgets.styles';
import { FeaturedCards } from '../cms-components/featured-cards/featured-cards';

const color_options = [
  { label: 'Light', value: 'light' },
  { label: 'Dark', value: 'dark' },
  { label: 'Mid', value: 'mid' },
];

const FeatureCards = (props: any) => {
  const { pageContext } = props;

  const [livePreviewData, setLivePreviewData] = useState<any>({});
  const [bgColor, setBgColor] = useState('mid' as BackgroundType);
  const [centered, setCentered] = useState(true);

  Stack.startLivePreview(true);

  const fetchData = useCallback(async () => {
    const response = (await Stack.getEntry({
      contentTypeUid: 'featured_cards_spz',
      url:
        pageContext?.url && pageContext?.url !== '/'
          ? pageContext?.url
          : '/live-preview',
    })) as any;

    const result = response[0][0];
    setLivePreviewData(result);
  }, [pageContext]);

  useEffect(() => {
    onEntryChange(() => fetchData());
  }, [fetchData]);

  return (
    <>
      <LivePreviewHeader>
        <LivePreviewSelect
          title="Background Color"
          options={color_options}
          defaultValue={bgColor}
          setSelectedItem={setBgColor}
        />
        <LivePreviewToggle label="Make center" toggleItem={setCentered} />
      </LivePreviewHeader>
      {!!livePreviewData && (
        <FeaturedCards
          {...livePreviewData}
          backgroundColor={bgColor}
          isCentered={centered}
        />
      )}
    </>
  );
};

export default FeatureCards;
